import React from 'react'
import PreviewerPageWrapper from '@apphiveio/controlsmanager/components/PreviewerPageWrapper'
import PreviewerPageSplash from '@apphiveio/controlsmanager/components/PreviewerPageSplash'

const compiledSplashScreenSource = undefined
const loadingMessage = ''
const background = undefined
const backgroundColor = '#ffffff'

const ClientAppSplashScreen = () => {
    if (compiledSplashScreenSource) {
        return <PreviewerPageWrapper backgroundSource={compiledSplashScreenSource} />
    }

    return (
        <PreviewerPageSplash
            message={loadingMessage}
            background={background}
            backgroundColor={backgroundColor}
        />
    )
}

export default ClientAppSplashScreen
